import React from 'react';
import {
    Button,
    Form,
    Modal, ModalContent
} from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
    getAddress,
    getPatientTitle,
    getToken,
    PatientTitleCase,
} from "../../../utils/common";
import styles from "../index.module.css";
import agent from "../../../agent";
import {TaskModel} from "../../workTableTask/TaskModel";

interface State {
    openModal: boolean,
    loading: boolean,
    isExporting: boolean,
    startDate: Date,
    endDate: Date,
}
interface Props {
    onClose: (task?: TaskModel) => void;
}

class PatientsAnswerModal extends React.Component<Props> {
    state: State = {
        openModal: true,
        loading: false,
        isExporting: false,
        startDate: new Date(),
        endDate: new Date(),
    };

    clearState = (task?: TaskModel): void => {
        this.setState({
            openModal: false,
            //startDate: null,
            //endDate: null,
            loading: false
        }, () => {
            this.props.onClose(task); // Call the onClose callback passed from the parent
        });
    };


    exportPatientsAnswer = async (): Promise<void> => {
        this.setState({loading: true});
        let task = await  agent.PatientProvider.exportPatientAnswers(
            this.state.startDate.toISOString().slice(0, 10),
            this.state.endDate.toISOString().slice(0, 10));
        this.clearState(task)
    };

    render() {
        const headerLabel: string = `Экспорт ответов ${getPatientTitle(PatientTitleCase.ov)}`;
        const exportLabel: string = `Экспортировать`;
        const cancelLabel: string = `Отмена`;

        return (
            <div className={styles.container}>

                <Modal
                    onClose={() => this.clearState()}
                    open={this.state.openModal}
                    closeIcon
                >
                    <Modal.Header>{headerLabel}</Modal.Header>
                    <ModalContent>
                        <Form>
                            <div>
                                <label>Дата начала выгрузки</label><br/>
                                <DatePicker
                                                selected={this.state.startDate}
                                                onChange={(date: Date) => {
                                                    // this.setState({
                                                    //     startDate: date,
                                                    //     endDate: date
                                                    // })
                                                    if (!date) {
                                                        const currentDate = new Date();
                                                        this.setState({
                                                            startDate: currentDate,
                                                            endDate: currentDate
                                                        });
                                                    } else {
                                                        this.setState({
                                                            startDate: date,
                                                            endDate: date
                                                        });
                                                    }
                                                }}
                                                locale={ru}
                                                dateFormat="dd.MM.yyyy"
                                />
                            </div>

                            <div style={{marginTop: '15px'}}>
                                <label style={{marginTop: '10px'}}>Дата окончания выгрузки</label><br/>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date: Date) => {
                                        this.setState({
                                            endDate: date
                                        })
                                    }}
                                    locale={ru}
                                    dateFormat="dd.MM.yyyy"
                                    minDate={this.state.startDate}
                                    maxDate={new Date(this.state.startDate.getTime() + 6 * 24 * 60 * 60 * 1000)}
                                />
                            </div>
                        </Form>
                    </ModalContent>
                    <Modal.Actions>
                        <Button
                            content={exportLabel}
                            onClick={this.exportPatientsAnswer}
                            positive
                        />
                        <Button
                            color={'grey'}
                            onClick={() => this.clearState()}
                        >
                            {cancelLabel}
                        </Button>
                    </Modal.Actions>
                </Modal>

            </div>

        );
    }
}

export default PatientsAnswerModal;
