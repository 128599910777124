import React, {ChangeEvent, useEffect, useState} from "react";
import {Menu,Icon} from "semantic-ui-react";


interface PagingFooterProps {
    prevPage?: number,
    nextPage?: number,
    page?: number,
    totalPages?: number,
    totalDocs?: number,
    loadPage?: (page: number) => void;
}

const PagingFooter = (props: PagingFooterProps) => {
    return (
        <Menu floated='right' pagination>
            <Menu.Item
                as='a'
                icon
                onClick={props.loadPage ? () => props.loadPage!(1) : undefined}
            >
                <Icon name='angle double left'/>
            </Menu.Item>

            {
                props.prevPage ?
                    <Menu.Item
                        as='a'
                        onClick={props.loadPage ? () => props.loadPage!(props.prevPage ?? 1) : undefined}
                    >
                        {props.prevPage}
                    </Menu.Item>
                    :
                    ''
            }

            <Menu.Item
                style={{background: 'lightgray'}}
            >
                {props.page}
            </Menu.Item>

            {
                props.nextPage ?
                    <Menu.Item
                        as='a'
                        onClick={props.loadPage ? () => props.loadPage!(props.nextPage??1) : undefined}
                    >
                        {props.nextPage}
                    </Menu.Item>
                    :
                    ''
            }

            <Menu.Item
                as='a'
                icon
                onClick={props.loadPage ? () => props.loadPage!(props.totalPages??1) : undefined}
            >
                <Icon name='angle double right'/>
            </Menu.Item>

            <Menu.Item>
                {`Страниц: ${props.totalPages !== undefined ? props.totalPages : 0}`}
            </Menu.Item>
            <Menu.Item>
                {`Записей: ${props.totalDocs}`}
            </Menu.Item>
        </Menu>
    );
}

export default PagingFooter;