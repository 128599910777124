import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button, Container,
    Dimmer,
    DropdownItemProps,
    DropdownProps,
    Form, Grid, GridColumn, Icon,
    InputOnChangeData, Label,
    Loader,
    Modal, Segment, Tab, Table, TextArea, TextAreaProps
} from "semantic-ui-react";
import styles from "./index.module.css";
import {Utils} from "../../helpers/Utils";
import {TaskModel} from "../workTableTask/TaskModel";
import {Link} from "react-router-dom";

interface Props {
    task: TaskModel,
    onClose: () => void;
}

const CreatedTaskDetailsModal = (props: Props) => {

    return (
        <Modal
            onClose={props.onClose}
            open={!!props.task}

            closeIcon>
            <Modal.Header>Создано системное задание</Modal.Header>
            <Modal.Content>
                <p>Создано системное задание с типом {props.task.type.displayName}, идентификатор {props.task._id}.</p>
                <p>Для получения результатов перейдите на вкладку "Системные задания".</p>
            </Modal.Content>
            <Modal.Actions>
                <Link to={`tasks`}>
                    <Button
                        color={'green'}
                        onClick={props.onClose}>
                        Перейти к системным заданиям
                    </Button>
                </Link>
                <Button
                    color={'grey'}
                    onClick={props.onClose}>
                    Закрыть
                </Button>
            </Modal.Actions>
        </Modal>);
}

export default CreatedTaskDetailsModal;