export enum TaskStatus {
    pending = "pending",
    executing = "executing",
    completed = "completed",
    cancelled = "cancelled"
}

export enum TaskResult {
    unknown = "unknown",
    success = "success",
    error = "error"
}

export enum TaskCreatorType {
    system = "system",
    admin = "admin",
    doctor = "doctor",
    observer = "observer"
}
export interface TaskTypeModel {
    id: String,
    displayName: String
}

export interface TaskTypeCreator {
    id: String,
    displayName: String,
    type: TaskCreatorType
}

export interface TaskJob {
    id: String,
    active: boolean,
    nextRunAt?: Date
}

export interface TaskModel {
    _id: string,
    type: TaskTypeModel,
    job: TaskJob,
    params: any,
    status: TaskStatus,
    result: TaskResult,
    error?: string,
    created: Date,
    creator?: TaskTypeCreator,
    startDate?: Date,
    endDate?: Date,
    files?: TaskFileModel[],
    comment?: string,
    log?: string
}

export interface TaskFileModel {
    id: string,
    name: string,
    size: number,
    displayName?: string,
    comment?: string
}

export function getTaskStatusDisplayName(status: TaskStatus): String {
    switch (status) {
        case TaskStatus.pending:
            return "Ожидание запуска";
        case TaskStatus.executing:
            return "Выполняется";
        case TaskStatus.cancelled:
            return "Отмена";
        case TaskStatus.completed:
            return "Выполнено";
    }
}

export function getTaskStatusColor(status: TaskStatus): string {
    switch (status) {
        case TaskStatus.pending:
            return "black";
        case TaskStatus.executing:
            return "blue";
        case TaskStatus.cancelled:
            return "orange";
        case TaskStatus.completed:
            return "green";
    }
}

export function getTaskResultColor(result: TaskResult): string {
    switch (result) {
        case TaskResult.unknown:
            return "black";
        case TaskResult.success:
            return "green";
        case TaskResult.error:
            return "red";
    }
}

export function getTaskResultDisplayName(result: TaskResult): String {
    switch (result) {
        case TaskResult.unknown:
            return "";
        case TaskResult.success:
            return "Успешно";
        case TaskResult.error:
            return "Ошибка";
    }
}