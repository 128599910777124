import {PatientFile} from "../components/dashboard";
import request from "superagent";
import agent from "../agent";
import {TestIndicator} from "../components/test/TestModel";
import moment from "moment/moment";
import {getTZ} from "../utils/common";

export interface OpenFileOptions {
    file?: PatientFile;
    fileId?: string;
    download?: boolean
}

export class Utils {
    static getRefsText(value: TestIndicator) {
        if (value.max && value.min)
            return value.min + " - " + value.max;
        else if (value.max)
            return "<=" + value.max;
        else if (value.min)
            return ">" + value.max;
        else
            return "-";
    }

    public static vomode(): number {
        return window.config?.vomode || 0;
    }

    public static async openFile(options: OpenFileOptions) {
        let data: request.Response | void;
        let file = options.file;
        if (!file && options.fileId) {
            file = await agent.FileProvider.open(options.fileId)
        }
        if (!file) return;
        //if (options.file)
        data = await agent.FileProvider.getFile(file.filename, file.uploadDate);
        // else if (options.fileId) {
        //     data = await agent.FileProvider.getFileById(options.fileId);
        // }

        if (data) {
            const newFile: Blob = new Blob([data.body], {type: file?.contentType ?? "application/pdf"})
            const newFileURL: string = URL.createObjectURL(newFile);

            if (!options.download) {
                window.open(newFileURL,
                    "mywindow", "menubar=1,resizable=1,width=" + (window.screen.width * 0.4) + ",height=" + window.screen.height + ",location=no");
            } else {
                if (file.contentType.includes('image')) {
                    window.open(newFileURL, '_blank');
                } else {
                    let a: HTMLAnchorElement = document.createElement('a');

                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.href = newFileURL;
                    a.download = file.filename;

                    a.click();
                }
            }
            URL.revokeObjectURL(newFileURL);
        }

    }

    public static formatDate(date?: Date): string {
        return this.formatDateTime(date, "DD.MM.YY")
    }

    public static formatTime(date?: Date): string {
        return this.formatDateTime(date, "HH:mm:ss")
    }

    public static formatDateTime(date?: Date, pattern?: string): string {
        let _pattern = pattern ?? "DD.MM.YY HH:mm:ss";
        if (date)
            return moment(date).tz(getTZ()).format(_pattern);
        else
            return "";
    }

}