import React, {ChangeEvent} from "react";
import styles from "../index.module.css";
import {Button, Dropdown, Form, Grid, Loader, Message, Modal, TextArea} from "semantic-ui-react";
import agent from "../../../agent";
import {Doctor, DoctorOptions, Patient, SpecialGroup, SpecialGroupOptions} from "../../dashboard";
import {Redirect} from "react-router-dom";
import ReactInputMask from "react-input-mask";
import {
    AppMode,
    convertMaskedDateStringToDate,
    DoctorTitleCase,
    EmptyID,
    GeneralTitleCase,
    getDoctorId,
    getDoctorTitle,
    getGeneralTitle,
    getPatientTitle,
    getSpecialGroupId,
    NullDate,
    PatientTitleCase
} from "../../../utils/common";
import {DoctorPaginationResponse} from "../../workTableDoctors/DoctorsList";
import DatePicker from "react-datepicker";
import {hasRole, UserRole} from "../../../helpers/UserRole";
import {Utils} from "../../../helpers/Utils";
import {SpecialGroupPaginationResponse} from "../../workTableSpecialGroups/SpecialGroupList";
import {firstLetterToUpperCase} from "../PatientsList";
import ru from "date-fns/locale/ru";

interface ExpirationCodeResponse {
    codeexpiredat: Date,
}

interface Props {
    match?: { path: string };
    id: string;
    backUrl: string;
}

interface State {
    editable: boolean,
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    snils: string,
    registrationaddress: string,
    residenceaddress: string,
    loading: boolean,
    saved: boolean,
    editMode: boolean,
    dateofbirth: string,
    registerDate: string,
    activatedDate?: Date,
    updateActivatedDate?: Date,
    activatedDateHasBeenChanged: boolean,
    openApproveChangeActivatedDateModal: boolean,
    autoActivated?: boolean,
    diagnosis: string,
    diary?: string,
    code: number,
    codeexpiredat: Date,
    activated: boolean,
    doctorid: string,
    codeLoading: boolean,
    doctors: Doctor[],
    specialgroupids: string[],
    specialgroups: SpecialGroup[],
    visiblespecialgroups: boolean,
    searchSpecialGroup: string,
    inactive: boolean,
    inactiveUntil?: Date,
    isLargeFamily?: boolean,
    inn?: string,
    botblocked?: boolean,
    telValid: boolean,
    emailValid: boolean,
    snilsValid: boolean,
    nameValid: boolean,
    surnameValid: boolean,
}

class PatientInfoTab extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        name: '',
        middlename: '',
        surname: '',
        tel: '',
        email: '',
        snils: '',
        registrationaddress: '',
        residenceaddress: '',
        loading: false,
        saved: false,
        dateofbirth: '',
        registerDate: '',
        activatedDate: undefined,
        updateActivatedDate: undefined,
        activatedDateHasBeenChanged: false,
        openApproveChangeActivatedDateModal: false,
        autoActivated: false,
        diagnosis: '',
        code: 0,
        codeexpiredat: new Date(0),
        activated: false,
        doctorid: '',
        codeLoading: false,
        doctors: [],
        specialgroupids: [],
        specialgroups: [],
        visiblespecialgroups: false,
        searchSpecialGroup: '',
        editMode: false,
        inactive: false,
        botblocked: false,
        telValid: false,
        emailValid: true,
        snilsValid: true,
        nameValid: false,
        surnameValid: false,
    };

    clearState = (): void => {
        this.setState({
            editable: false,
            _id: '',
            name: '',
            middlename: '',
            surname: '',
            tel: '',
            email: '',
            snils: '',
            registrationaddress: '',
            residenceaddress: '',
            loading: false,
            saved: false,
            dateofbirth: '',
            registerDate: '',
            activatedDate: undefined,
            updateActivatedDate: undefined,
            activatedDateHasBeenChanged: false,
            openApproveChangeActivatedDateModal: false,
            autoActivated: false,
            diagnosis: '',
            code: 0,
            codeexpiredat: new Date(0),
            activated: false,
            doctorid: '',
            codeLoading: false,
            doctors: [],
            specialgroupids: [],
            specialgroups: [],
            visiblespecialgroups: false,
            searchSpecialGroup: '',
            editMode: false,
            inactive: false,
            botblocked: false,
            telValid: false,
            emailValid: true,
            snilsValid: true,
            nameValid: false,
            surnameValid: false,
        })
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({ editMode: true, loading: true });
            await this.onMount();
        } else {
            this.setState({ loading: true })
            const response: DoctorPaginationResponse = await agent.DoctorProvider.getList();
            this.setState({ doctors: response.docs, loading: false, editable: true })
            const doctorId = await getDoctorId();
            if (doctorId)
                this.setState({
                    doctorid: doctorId,
                });
            const responseSp: SpecialGroupPaginationResponse = await agent.SpecialGroupProvider.getList();
            this.setState({ specialgroups: responseSp.docs, loading: false, editable: true });
            const specialGroupIds = await getSpecialGroupId();
            if (specialGroupIds)
                this.setState({
                    specialgroupids: specialGroupIds,
                });
        }
    };

    onMount = async (): Promise<void> => {
        const patient: Patient = await agent.PatientProvider.open(this.props.id);

        if (patient) {
            this.setState({
                _id: patient._id,
                name: patient.name,
                middlename: patient.middlename,
                surname: patient.surname,
                tel: patient.tel,
                email: patient.email,
                snils: patient.snils ? patient.snils : '',
                registrationaddress: patient.registrationaddress ? patient.registrationaddress : '',
                residenceaddress: patient.residenceaddress ? patient.residenceaddress : '',
                dateofbirth: new Date(patient.dateofbirth).toLocaleDateString("RU"),
                registerDate: patient.registerDate ? new Date(patient.registerDate).toLocaleDateString("RU") : '',
                activatedDate: patient.activatedDate ? patient.activatedDate : undefined,
                updateActivatedDate: patient.updateActivatedDate ? patient.updateActivatedDate : undefined,
                autoActivated: patient.autoActivated,
                diagnosis: patient.diagnosis,
                diary: patient.diary,
                isLargeFamily: patient.isLargeFamily,
                code: patient.code ? patient.code : 0,
                codeexpiredat: patient.codeexpiredat ? patient.codeexpiredat : new Date(0),
                activated: patient.activated ? patient.activated : false,
                doctorid: patient.doctorid ? patient.doctorid : '',
                specialgroupids: patient.specialgroupids ? patient.specialgroupids : [],
                inactive: patient.inactive === true,
                inactiveUntil: patient.inactiveUntil ? new Date(patient.inactiveUntil!) : undefined,
                inn: patient.inn,
                botblocked: patient.botblocked === true
            });

            const response: DoctorPaginationResponse = await agent.DoctorProvider.getList();

            this.setState({
                doctors: response.docs,
                loading: false,
            });
            const responseSp: SpecialGroupPaginationResponse = await agent.SpecialGroupProvider.getList();

            this.setState({
                specialgroups: responseSp.docs,
                loading: false,
            });
        }
    };

    cancelSave = async (): Promise<void> => {
        this.clearState();
        await this.componentDidMount()
    }

    onCreate = async (): Promise<void> => {
        this.setState({ loading: true });

        const patientId = await agent.PatientProvider.create({
            _id: this.state._id,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            snils: this.state.snils,
            registrationaddress: this.state.registrationaddress,
            residenceaddress: this.state.residenceaddress,
            dateofbirth: !!convertMaskedDateStringToDate(this.state.dateofbirth) ?
                convertMaskedDateStringToDate(this.state.dateofbirth)! : NullDate,
            diagnosis: this.state.diagnosis,
            diary: this.state.diary,
            isLargeFamily: this.state.isLargeFamily,
            inactive: this.state.inactive,
            inactiveUntil: this.state.inactiveUntil,
            doctorid: this.state.doctorid ? this.state.doctorid : EmptyID,
            specialgroupids: this.state.specialgroupids ? this.state.specialgroupids : [],
            inn: this.state.inn
        });
        this.setState({ loading: false, saved: true });
        const currentPath: string = this.props.match ? this.props.match.path.replace('/create', '') : '';
        window.location.replace(`${currentPath}/${patientId}/edit/patientInfo`);
    };

    onEdit = async () => {
        this.setState({ loading: true });

        await agent.PatientProvider.edit({
            _id: this.state._id,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            snils: this.state.snils,
            registrationaddress: this.state.registrationaddress,
            residenceaddress: this.state.residenceaddress,
            dateofbirth: convertMaskedDateStringToDate(this.state.dateofbirth) ?
                convertMaskedDateStringToDate(this.state.dateofbirth)! : NullDate,
            activatedDate: this.state.activatedDate,
            activatedDateHasBeenChanged: this.state.activatedDateHasBeenChanged,
            diagnosis: this.state.diagnosis,
            doctorid: this.state.doctorid ? this.state.doctorid : EmptyID,
            specialgroupids: this.state.specialgroupids ? this.state.specialgroupids : [],
            inactive: this.state.inactive,
            inactiveUntil: this.state.inactiveUntil,
            diary: this.state.diary,
            isLargeFamily: this.state.isLargeFamily,
            inn: this.state.inn,
            botblocked: this.state.botblocked
        }).then((patient: Patient) => {
            this.setState({
                _id: patient._id,
                doctorid: patient.doctorid ? patient.doctorid : '',
                specialgroupids: patient.specialgroupids ? patient.specialgroupids : [],
                name: patient.name,
                middlename: patient.middlename,
                surname: patient.surname,
                tel: patient.tel,
                email: patient.email,
                snils: patient.snils ? patient.snils : '',
                registrationaddress: patient.registrationaddress ? patient.registrationaddress : '',
                residenceaddress: patient.residenceaddress ? patient.residenceaddress : '',
                dateofbirth: new Date(patient.dateofbirth).toLocaleDateString("RU"),
                activatedDate: patient.activatedDate,
                updateActivatedDate: patient.updateActivatedDate,
                diagnosis: patient.diagnosis,
                diary: patient.diary,
                isLargeFamily: patient.isLargeFamily,
                code: patient.code ? patient.code : 0,
                inn: patient.inn,
                codeexpiredat: patient.codeexpiredat ? patient.codeexpiredat : new Date(0),
                activated: patient.activated ? patient.activated : false,
            });
        });

        this.setState({ loading: false, editable: false, activatedDateHasBeenChanged: false });
    };

    getDoctorsOptions = (): DoctorOptions[] => {
        return this.state.doctors.map((el: Doctor) => ({
            key: el._id,
            text: `${el.surname} ${el.name} ${el.middlename}`,
            value: el._id,
        }))
    };

    getDefaultDoctor = (): string => {
        let doctor: string = '';

        this.state.doctors && this.state.doctors.forEach((doc: Doctor) => {
            if (doc._id === this.state.doctorid) {
                doctor = `${doc.surname} ${doc.name} ${doc.middlename}`

                return
            }
        });

        return doctor;
    };

    getSelectedDoctorFullName = (): string => {
        const doctor: Doctor | undefined = this.state.doctors.find((el: Doctor) => el._id === this.state.doctorid);

        return doctor ? `${doctor.surname} ${doctor.name} ${doctor.middlename}` : '';
    };

    getSpecialGroupsOptions = (): SpecialGroupOptions[] => {
        return this.state.specialgroups.map((el: SpecialGroup) => ({
            key: el._id,
            text: `${el.name}`,
            value: el._id,
        }))
    };

    getSelectedSpecialGroups = (): string => {
        const selectedspecialgroup = this.state.specialgroups.filter(specialgroup => this.state.specialgroupids?.includes(specialgroup._id.toString()));
        return selectedspecialgroup ? selectedspecialgroup.map(specialgroup => specialgroup.name).join("; ") : '';
    };

    getCodeExpirationLabel = (): string => {
        const date: Date = new Date(this.state.codeexpiredat);

        if (this.state.codeexpiredat && new Date() < date) {
            return `Код активации. Истекает: ${date.toLocaleString("RU")}`
        } else {
            return `Код активации истёк`
        }
    };

    onRefreshCodeExpiration = async (): Promise<void> => {
        this.setState({ codeLoading: true });

        const response: ExpirationCodeResponse = await agent.PatientProvider.refreshCodeExpiration(this.state._id);

        if (response) {
            this.setState({ codeexpiredat: response.codeexpiredat })
        }

        this.setState({ codeLoading: false });
    };

    isSaveDisabled = (): boolean => {
        // return !this.state.surname &&
        return !this.state.surnameValid || !this.state.nameValid || !this.state.telValid ||
            !(this.state.snils=='' || (this.state.snils!=='' && this.state.snilsValid));
    };

    copyDataToClipboard = () => {
        const el = document.createElement("textarea");
        el.value = `${this.state.surname} ${this.state.name}${(this.state.middlename && this.state.middlename !== '') ? ' ' + this.state.middlename : ''}` +
            `${this.state.registerDate && '\nДата регистрации: ' + this.state.registerDate}` +
            `${this.state.activatedDate!==undefined ? '\nДата активации: ' + new Date(this.state.activatedDate).toLocaleDateString("RU") : ''} ` +
            `${this.state.dateofbirth && '\nДата рождения: ' + this.state.dateofbirth}` +
            `${(this.state.inn && this.state.inn !== '') ? '\nИНН: ' + this.state.inn : ''}` +
            `${this.state.tel && '\nНомер телефона: ' + this.state.tel}` +
            `${(this.state.email && this.state.email !== '') ? '\nЭлектронная почта: ' + this.state.email : ''}` +
            `${(this.state.snils && this.state.snils !== '') ? '\nСНИЛС: ' + this.state.snils : ''}`+
            `${(this.state.registrationaddress && this.state.registrationaddress !== '') ? '\nАдрес регистрации: ' + this.state.registrationaddress : ''}`+
            `${(this.state.residenceaddress && this.state.residenceaddress !== '') ? '\nАдрес фактического проживания: ' + this.state.residenceaddress : ''}`+
            `${(this.state.doctorid && this.state.doctorid !== '000000000000000000000000') ? '\n' + firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im)) + ': ' + this.getSelectedDoctorFullName() : ''}` +
            `${(this.state.specialgroupids && this.getSelectedSpecialGroups() !== '') ? `\n${getGeneralTitle(GeneralTitleCase.specialgrouptab)}: ` + this.getSelectedSpecialGroups() : ''}` +
            `${(this.state.diagnosis && this.state.diagnosis !== '') ? '\n' + (getGeneralTitle(GeneralTitleCase.diagnostab)) + ': ' + this.state.diagnosis : ""}` +
            `${(this.state.diary && this.state.diary !== '') ? '\nДневник: ' + this.state.diary : ''}`;

        el.style.display = "hidden";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }

    render() {
        const NilObjectId: string = '000000000000000000000000';

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/patients'} />
                :
                <div className={this.state.editMode ? styles.patientInfoContent : styles.patientCreating}>
                    {
                        this.state.editMode ? ''
                            :
                            <div className={styles.patientInfoHeader}>
                                Создание {getPatientTitle(PatientTitleCase.a)}
                            </div>

                    }
                    {
                        this.state.loading ?
                            <Loader active>Список загружается...</Loader>
                            :
                            <Form>
                                {
                                    this.state.openApproveChangeActivatedDateModal &&
                                    <Modal
                                        onClose={() => this.setState({openApproveChangeActivatedDateModal: false})}
                                        open={this.state.openApproveChangeActivatedDateModal}
                                        closeIcon
                                    >
                                        <Modal.Header>Изменение даты активации</Modal.Header>
                                        <Modal.Content>Вы изменили дату активации. Вы уверены, что хотите сохранить новые данные, в том числе дату активации?</Modal.Content>
                                        <Modal.Actions>
                                            <Button
                                                content={'Да, сохранить'}
                                                onClick={() => {
                                                    this.state.editMode ? this.onEdit() : this.onCreate();
                                                    this.setState({openApproveChangeActivatedDateModal: false});
                                                }}
                                                positive
                                            />
                                            <Button
                                                content={'Нет, не сохранять'}
                                                onClick={() => {
                                                    this.cancelSave();
                                                    this.setState({openApproveChangeActivatedDateModal: false});
                                                }}
                                                negative
                                            />
                                            <Button
                                                color={'grey'}
                                                onClick={() => this.setState({openApproveChangeActivatedDateModal: false})}
                                            >
                                                Отмена
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                }
                                <Grid stackable columns={3} stretched>
                                    <Grid.Column>
                                            {
                                                this.state.editable ?
                                                    <Button
                                                        color={'green'}
                                                        type={'submit'}
                                                        content={'Сохранить'}
                                                        disabled={this.isSaveDisabled()}
                                                        onClick={this.state.activatedDateHasBeenChanged
                                                            ? () => this.setState({openApproveChangeActivatedDateModal: true})
                                                            : (this.state.editMode ? this.onEdit : this.onCreate)}
                                                    />
                                                    :
                                                    <Button
                                                        color={'teal'}
                                                        type={'submit'}
                                                        content={'Изменить'}
                                                        disabled={hasRole(UserRole.OBSERVER)}
                                                        onClick={() => this.setState({
                                                            editable: true,
                                                            telValid: true,
                                                            nameValid: true,
                                                            surnameValid: true
                                                        })}
                                                    />
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                        <Button
                                        color={'violet'}
                                        basic
                                        content={'Копировать данные'}
                                        disabled={hasRole(UserRole.OBSERVER)}
                                        onClick={this.copyDataToClipboard}
                                        />
                                        </Grid.Column>
                                {
                                        this.state.editMode ?

                                            <Grid.Column>
                                                <div className={styles.codeInfo}>
                                                    {
                                                        this.state.activated ?
                                                            <Message
                                                                size={'mini'}
                                                                header={Utils.vomode()!=AppMode.PATIENT ? 'Бот активирован' : 'Пациент активирован'}
                                                            />
                                                            :
                                                            <>
                                                                <Form.Input label={this.getCodeExpirationLabel()}
                                                                    value={this.state.code}
                                                                    readOnly />
                                                                <Form.Button floated={'left'}
                                                                    color={'blue'}
                                                                    fluid
                                                                    loading={this.state.codeLoading}
                                                                    disabled={hasRole(UserRole.OBSERVER)}
                                                                    onClick={this.onRefreshCodeExpiration}>
                                                                    Продлить срок действия кода
                                                                </Form.Button>
                                                            </>
                                                    }
                                                </div>
                                            </Grid.Column>
                                            :
                                            ''
                                    }
                                </Grid>
                                <Grid stackable columns={5} stretched>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Неактивный {getPatientTitle(PatientTitleCase.im)}</label>
                                            <Form.Checkbox
                                                checked={this.state.inactive}
                                                readOnly={!this.state.editable}
                                                toggle
                                                onChange={(event, data) =>
                                                    this.setState({
                                                        inactive: data.checked === true,
                                                        inactiveUntil: data.checked ?
                                                            this.state.inactiveUntil : undefined
                                                    })} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field disabled={!this.state.inactive}>
                                            <label>Неактивен до</label>
                                            <DatePicker selected={this.state.inactiveUntil}
                                                locale={"ru"}
                                                dateFormat={"dd.MM.yyyy"}
                                                disabled={!this.state.inactive}
                                                onChange={(v: Date) => {
                                                    this.setState({
                                                        inactiveUntil: v
                                                    })
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <label>Многодетная семья</label>
                                            <Form.Checkbox
                                                checked={this.state.isLargeFamily}
                                                readOnly={!this.state.editable}
                                                toggle
                                                fitted

                                                onChange={(event, data) =>
                                                    this.setState({
                                                        isLargeFamily: data.checked === true
                                                    })} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Form.Field disabled={this.state.editable}>
                                            <label>Дата регистрации</label>
                                            <ReactInputMask
                                                mask={'99.99.9999'}
                                                alwaysShowMask={false}
                                                value={this.state.registerDate}
                                                readOnly={true}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={'4'}>
                                        <Form.Field disabled={!this.state.editMode || (this.state.editable && (this.state.autoActivated || !this.state.activated))}>
                                            <label>Дата активации{(this.state.autoActivated || this.state.updateActivatedDate===undefined) ? '' : ` (обновлено: ${new Date(this.state.updateActivatedDate).toLocaleDateString("RU")})`}</label>
                                            <DatePicker
                                                selected={this.state.activatedDate ? new Date(this.state.activatedDate) : null}
                                                onChange={(date: Date) => {
                                                    this.setState({
                                                        activatedDate: date,
                                                        activatedDateHasBeenChanged: true
                                                    })
                                                }}
                                                locale={ru}
                                                dateFormat="dd.MM.yyyy"
                                                maxDate={new Date()}
                                                readOnly={!this.state.editable || this.state.autoActivated}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {window.config?.moniki == true && hasRole(UserRole.ADMIN) &&
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Бот заблокирован</label>
                                                <Form.Checkbox
                                                    checked={this.state.botblocked}
                                                    readOnly={!this.state.editable}
                                                    toggle
                                                    onChange={(event, data) =>
                                                        this.setState({
                                                            botblocked: data.checked === true
                                                        })} />
                                            </Form.Field>

                                        </Grid.Column>}

                                </Grid>

                                <Form.Group widths={"equal"}>
                                    <Form.Input label={'Фамилия'}
                                        fluid
                                        value={this.state.surname}
                                        required={true}
                                        readOnly={!this.state.editable}
                                        // onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({surname: e.target.value})}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            //Требуемая длина от 2 букв
                                            const inputValue = e.target.value;
                                            const isNonEmpty = inputValue.trim() !== '';
                                            const filteredValue = isNonEmpty ? inputValue.replace(/[^а-яё\-\s]/gi, '') : '';
                                            const length = filteredValue.length;
                                            const isValid = isNonEmpty ? length >= 2 : false;
                                            this.setState({
                                                surname: filteredValue,
                                                surnameValid: isValid,
                                            });
                                        }}
                                        error={!this.state.surnameValid && this.state.editable}
                                        content={!this.state.surnameValid && this.state.surname !== '' && "Требуется длина от 2 букв"}
                                    />
                                    <Form.Input label={'Имя'}
                                        required={true}
                                        value={this.state.name}
                                        readOnly={!this.state.editable}
                                        // onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            //Требуемая длина от 2 букв
                                            const inputValue = e.target.value;
                                            const isNonEmpty = inputValue.trim() !== '';
                                            const filteredValue = isNonEmpty ? inputValue.replace(/[^а-яё\-\s]/gi, '') : '';
                                            const length = filteredValue.length;
                                            const isValid = isNonEmpty ? length >= 2 : false;
                                            this.setState({
                                                name: filteredValue,
                                                nameValid: isValid,
                                            });
                                        }}
                                        error={!this.state.nameValid && this.state.editable}
                                        content={!this.state.nameValid && this.state.name !== '' && "Требуется длина от 2 букв"}
                                    />
                                    <Form.Input label={'Отчество'}
                                        value={this.state.middlename}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ middlename: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                    <Form.Field>
                                        <label>Дата рождения</label>
                                        <ReactInputMask
                                            mask={'99.99.9999'}
                                            alwaysShowMask={false}
                                            value={this.state.dateofbirth}
                                            readOnly={!this.state.editable}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ dateofbirth: e.target.value })}
                                        />
                                    </Form.Field>
                                    {Utils.vomode()!=AppMode.PATIENT &&
                                        <Form.Input label={'ИНН'}
                                            value={this.state.inn}
                                            readOnly={!this.state.editable}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ inn: e.target.value })}
                                        />}
                                    <Form.Input
                                        label="Номер телефона"
                                        value={this.state.tel}
                                        required={true}
                                        readOnly={!this.state.editable}
                                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                            if (e.target.value.length < 1) {
                                                this.setState({ tel: '+7' + this.state.tel });
                                            }
                                        }}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const inputValue = e.target.value;
                                            const isNonEmpty = inputValue.trim() !== '';
                                            // const filteredValue = isNonEmpty ? inputValue.replace(/[^0-9+()-]/g, '') : '';
                                            const filteredValue = isNonEmpty ? inputValue.replace(/[^0-9+]/g, '') : '';
                                            const filteredValueInt = isNonEmpty ? inputValue.replace(/[^0-9]/g, '') : '';
                                            const filteredValuePlus = isNonEmpty ? inputValue.replace(/[^+]/g, '') : '';
                                            // const filteredValueBracketOn = isNonEmpty ? inputValue.replace(/[^(]/g, '') : '';
                                            // const filteredValueBracketOff  = isNonEmpty ? inputValue.replace(/[^)]/g, '') : '';
                                            const lengthInt = filteredValueInt.length;
                                            const lengthPlus = filteredValuePlus.length;
                                            // const lengthBracketOn  = filteredValueBracketOn .length;
                                            // const lengthBracketOff  = filteredValueBracketOff .length;
                                            // const isValid = isNonEmpty ? (lengthInt>=7 && lengthInt<=11 && lengthPlus<=1 && lengthBracketOn<=1 && lengthBracketOff<=1) : false;
                                            const isValid = isNonEmpty ? (lengthInt >= 7 && lengthInt <= 11 && lengthPlus <= 1) : false;
                                            this.setState({
                                                tel: filteredValue,
                                                telValid: isValid,
                                            });
                                        }}
                                        error={!this.state.telValid && this.state.editable}
                                        content={!this.state.telValid && this.state.tel !== '' && "Требуется длина от 7 до 11 цифр"}
                                    />
                                    <Form.Input
                                        //Проверка на @, точку и по хотя бы одному англ символу и цифр между
                                        label="Электронная почта"
                                        value={this.state.email}
                                        type="email"
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const inputValue = e.target.value;
                                            const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue);

                                            this.setState({
                                                email: inputValue,
                                                emailValid: isValid,
                                            });
                                        }}
                                        error={!this.state.emailValid}
                                        content={!this.state.emailValid && "Введите правильный адрес электронной почты"}
                                    />
                                </Form.Group>
                                <Form.Group widths={"equal"}>
                                    <Form.Field>
                                        <label>СНИЛС</label>
                                        <ReactInputMask
                                            mask={'999-999-999 99'}
                                            alwaysShowMask={false}
                                            value={this.state.snils}
                                            readOnly={!this.state.editable}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const inputValue = e.target.value;
                                                const isValid = /^\d{3}-\d{3}-\d{3} \d{2}$/.test(inputValue);
                                                this.setState({ snils: inputValue, snilsValid: isValid})
                                                }
                                            }
                                            style={{ border: (this.state.snilsValid || (!this.state.snilsValid && this.state.snils=='')) ? '' : '1px solid red' }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Адрес регистрации</label>
                                        <Form.Input as={TextArea}
                                            value={this.state.registrationaddress}
                                            readOnly={!this.state.editable}
                                            maxLength={1000}
                                            rows={1}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const inputValue = e.target.value;
                                                this.setState({
                                                    registrationaddress: inputValue,
                                                });
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Адрес фактического проживания</label>
                                        <Form.Input as={TextArea}
                                            value={this.state.residenceaddress}
                                            readOnly={!this.state.editable}
                                            maxLength={1000}
                                            rows={1}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const inputValue = e.target.value;
                                                this.setState({
                                                    residenceaddress: inputValue,
                                                });
                                            }}
                                        />
                                        </Form.Field>
                                </Form.Group>
                                {
                                    this.state.editable ?
                                        <Form.Field>
                                            <label>{firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}</label>
                                            <Dropdown
                                                placeholder={(this.state.doctorid && this.state.doctorid !== NilObjectId) ? this.getDefaultDoctor() :
                                                    `Выберите ${getDoctorTitle(DoctorTitleCase.a)}`}
                                                search
                                                selection
                                                clearable
                                                disabled={!this.state.editable || !hasRole(UserRole.ADMIN)}
                                                options={this.getDoctorsOptions()}
                                                onChange={(e, data) => this.setState({ doctorid: data.value as string })}
                                            />
                                        </Form.Field>
                                        :
                                        <Form.Input label={firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}
                                            value={this.getSelectedDoctorFullName()}
                                            readOnly />

                                }

                                {
                                    this.state.editable ?
                                        <div>
                                            <button type="button" onClick={() => this.setState({ visiblespecialgroups: !this.state.visiblespecialgroups })}
                                                style={{ background: 'white', border: "1px solid gray", borderRadius: '3px', margin: '0 0 5px 0', padding: '5px' }}
                                            >
                                                {this.state.visiblespecialgroups ? "Скрыть" : "Показать"} {getGeneralTitle(GeneralTitleCase.specialgrouptab)}
                                            </button>
                                            {this.state.visiblespecialgroups && (
                                                <Form.Field>
                                                    <label>{getGeneralTitle(GeneralTitleCase.specialgrouptab)}</label>
                                                    <div className={styles.special_group_area}>
                                                        <div className={styles.special_group_search}>
                                                            <input
                                                                type="text"
                                                                value={this.state.searchSpecialGroup}
                                                                onChange={(e) => this.setState({ searchSpecialGroup: e.target.value })}
                                                                placeholder="Поиск"
                                                                style={{ width: '30%' }}
                                                            />
                                                            <Button basic color='orange' content='Сбросить'
                                                                onClick={() => this.setState({ searchSpecialGroup: '' })}
                                                                style={{ marginLeft: '10px' }}
                                                            />
                                                        </div>
                                                        {this.getSpecialGroupsOptions()
                                                            .filter((option) => option.text.toLowerCase().includes(this.state.searchSpecialGroup.toLowerCase()))
                                                            .map((option) => (
                                                            <label key={option.value} style={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}>
                                                                <input
                                                                    type="checkbox"
                                                                    value={option.value}
                                                                    checked={this.state.specialgroupids.includes(option.value)}
                                                                    style={{ marginRight: '5px' }}
                                                                    disabled={!this.state.editable}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                        const { value, checked } = e.target;
                                                                        this.setState((prevState) => {
                                                                            if (checked) {
                                                                                return { specialgroupids: [...prevState.specialgroupids, value] };
                                                                            } else {
                                                                                return {
                                                                                    specialgroupids: prevState.specialgroupids.filter(
                                                                                        (id) => id !== value
                                                                                    ),
                                                                                };
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                                {option.text}
                                                            </label>
                                                        ))}
                                                    </div>
                                                    {/*<select*/}
                                                    {/*    className={styles.select_multiple}*/}
                                                    {/*    multiple={true}*/}
                                                    {/*    value={this.state.specialgroupids}*/}
                                                    {/*    onChange={(e) => this.setState({ specialgroupids: Array.from(e.target.selectedOptions, (option) => option.value) })}*/}
                                                    {/*    disabled={!this.state.editable}*/}
                                                    {/*>*/}
                                                    {/*    {this.getSpecialGroupsOptions().map((option) => (*/}
                                                    {/*        <option key={option.value} value={option.value}>{option.text}</option>*/}
                                                    {/*    ))}*/}
                                                    {/*</select>*/}
                                                </Form.Field>)}
                                        </div>
                                        :
                                        <Form.Field>
                                            <label>{getGeneralTitle(GeneralTitleCase.specialgrouptab)}</label>
                                            <Form.Input
                                                as={TextArea}
                                                control={'string'}
                                                rows={1}
                                                value={this.getSelectedSpecialGroups()}
                                                readOnly
                                            />
                                        </Form.Field>
                                }

                                <Form.Field>
                                    <label>{getGeneralTitle(GeneralTitleCase.diagnostab)}</label>
                                    <Form.Input as={TextArea}
                                        control={'string'}
                                        rows={2}
                                        value={this.state.diagnosis}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ diagnosis: e.target.value })}
                                    />

                                </Form.Field>
                                <Form.Field>
                                    <label>Дневник</label>
                                    <Form.Input as={TextArea}
                                        control={'string'}
                                        rows={16}
                                        value={this.state.diary}
                                        readOnly={!this.state.editable}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ diary: e.target.value })}
                                    />

                                </Form.Field>

                            </Form>
                    }
                </div>
        )
    }

}

export default PatientInfoTab;