import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button, Container,
    Dimmer,
    DropdownItemProps,
    DropdownProps,
    Form, Grid, GridColumn, Icon,
    InputOnChangeData, Label,
    Loader,
    Modal, Segment, Tab, Table, TextArea, TextAreaProps
} from "semantic-ui-react";
import moment from "moment";
import styles from "./index.module.css";
import {
    getTaskResultColor,
    getTaskResultDisplayName,
    getTaskStatusColor,
    getTaskStatusDisplayName,
    TaskModel
} from "./TaskModel";
import agent from "../../agent";
import {Utils} from "../../helpers/Utils";
import PagingFooter from "../common/PagingFooter";

interface Props {
    task: TaskModel,
    onClose: () => void;
}

const TaskDetailsModal = (props: Props) => {

    const panes = [
        {
            menuItem: 'Общая информация', render: () => <Tab.Pane style={{minHeight: 500}}>
                <div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Идентификатор:</div>
                        <p className={styles.fieldValue}>{props.task._id}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Тип задания:</div>
                        <p className={styles.fieldValue}>{props.task.type.displayName}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Статус:</div>
                        <p className={styles.fieldValue}
                           style={{color: getTaskStatusColor(props.task.status)}}>{getTaskStatusDisplayName(props.task.status)}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Результат:</div>
                        <p className={styles.fieldValue}
                           style={{color: getTaskResultColor(props.task.result)}}>{getTaskResultDisplayName(props.task.result)}</p>
                    </div>
                    {props.task.error &&
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Ошибка:</div>
                        <p className={styles.fieldValue}>{props.task.error}</p>
                    </div>
                    }
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Создано:</div>
                        <p className={styles.fieldValue}>{Utils.formatDateTime(props.task.created)}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Кем создано:</div>
                        <p className={styles.fieldValue}>{props.task.creator?.displayName}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Время запуска:</div>
                        <p className={styles.fieldValue}>{Utils.formatDateTime(props.task.startDate)}</p>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Время завершения:</div>
                        <p className={styles.fieldValue}>{Utils.formatDateTime(props.task.endDate)}</p>
                    </div>
                </div>
            </Tab.Pane>
        },
        {
            menuItem: 'Журнал выполнения', render: () => <Tab.Pane style={{minHeight: 500}}>
                <div>
                    <Form>
                        <Form.TextArea value={props.task.log} style={{minHeight: 450}}/>
                    </Form>
                </div>
            </Tab.Pane>
        },
        {
            menuItem: 'Файлы (' + props.task.files?.length + ')', render: () => <Tab.Pane style={{minHeight: 500}}>
                <Table celled verticalAlign='middle' color={"teal"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Имя файла</Table.HeaderCell>
                            <Table.HeaderCell>Размер</Table.HeaderCell>
                            <Table.HeaderCell>Загрузка</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {props.task.files?.map((el, index) =>
                            <Table.Row key={`key-${index}-${el.id}`} style={{cursor: "pointer"}}>
                                <Table.Cell>{el.name}</Table.Cell>
                                <Table.Cell>{el.size}</Table.Cell>
                                <Table.Cell>
                                    <Button icon onClick={(e, b) => {
                                        Utils.openFile({fileId: el.id, download: true});
                                    }}>
                                        <Icon name={"download"}/>
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

            </Tab.Pane>
        }
    ]


    return (
        <div>
            <Modal
                onClose={props.onClose}
                open={!!props.task}
                closeIcon>
                <Modal.Header>Системное задание: {props.task.type.displayName}</Modal.Header>
                <Modal.Content>
                    <Tab panes={panes}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color={'grey'}
                        onClick={props.onClose}>
                        Закрыть
                    </Button>
                </Modal.Actions>
            </Modal></div>);
}

export default TaskDetailsModal;