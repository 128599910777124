import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button,
    Dimmer, Dropdown, DropdownItemProps, DropdownProps,
    Form,
    FormButton,
    Grid,
    Header,
    Icon, Input,
    InputOnChangeData,
    Loader,
    Menu,
    Message,
    Modal, Popup,
    Table
} from "semantic-ui-react";
import agent, {PaginateOptions, TaskListRequest, TaskListResponse} from "../../agent";
import {
    getTaskResultColor,
    getTaskResultDisplayName,
    getTaskStatusColor,
    getTaskStatusDisplayName, TaskFileModel,
    TaskModel
} from "./TaskModel";
import {Utils} from "../../helpers/Utils";
import styles from "../workTableDepts/index.module.css";
import PagingFooter from "../common/PagingFooter";
import {
    DoctorTitleCase,
    GeneralTitleCase,
    getDoctorTitle,
    getGeneralTitle,
    getPatientTitle,
    PatientTitleCase
} from "../../utils/common";
import {firstLetterToUpperCase} from "../workTablePatients/PatientsList";
import DatePicker from "react-datepicker";
import TestResultEditModal from "../workTablePatients/EditorTabs/test/TestResultEditModal";
import TaskDetailsModal from "./TaskDetailsModal";
import {Link} from "react-router-dom";

const TaskList = () => {
    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState<TaskListRequest>({
        page: 1, limit: 10
    });
    const [response, setResponse] = useState<TaskListResponse>();
    const [task, setTask] = useState<TaskModel>();
    const [popupVisible, setPopupVisible] = useState(false);


    useEffect(() => {
        load().then();
    }, []);


    async function load() {
        setLoading(true);
        setResponse(await agent.TaskProvider.list(request));
        setLoading(false);
    }

    function loadPage(page: number) {
        setRequest(Object.assign(request, {page: page}));
        load().then(() => {
        });
    }

    return (
        <div className={styles.table} style={{"paddingTop": "32px"}}>
            {!!task &&
            <TaskDetailsModal task={task!}
                              onClose={() => setTask(undefined)}
            />}

            <Header>Список системных заданий</Header>

            <Button color={"teal"} icon floated={"right"} style={{marginBottom: "16px"}} onClick={() => loadPage(1)}>
                <Icon name={"refresh"}/>&nbsp;&nbsp;Обновить
            </Button>

            <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Тип</Table.HeaderCell>
                        <Table.HeaderCell>Создано</Table.HeaderCell>
                        <Table.HeaderCell>Кем создано</Table.HeaderCell>
                        <Table.HeaderCell>Статус</Table.HeaderCell>
                        <Table.HeaderCell>Результат</Table.HeaderCell>
                        <Table.HeaderCell>Время запуска</Table.HeaderCell>
                        <Table.HeaderCell>Время остановки</Table.HeaderCell>
                        <Table.HeaderCell>Плановое время</Table.HeaderCell>
                        <Table.HeaderCell>Файлы</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {response?.docs.map((el, index) =>
                        <Table.Row key={`key-${index}-${el._id}`} style={{cursor: "pointer"}} onClick={() => {
                            if (!popupVisible) {
                                agent.TaskProvider.open(el._id).then((task) => setTask(task));
                            }
                        }}>
                            <Table.Cell>{el.type.displayName}</Table.Cell>
                            <Table.Cell>{Utils.formatDateTime(el.created)}</Table.Cell>
                            <Table.Cell>{el.creator?.displayName}</Table.Cell>
                            <Table.Cell><p style={{"color": getTaskStatusColor(el.status)}}>
                                {getTaskStatusDisplayName(el.status)}</p></Table.Cell>
                            <Table.Cell><p style={{"color": getTaskResultColor(el.result)}}>
                                {getTaskResultDisplayName(el.result)}</p></Table.Cell>
                            <Table.Cell>{Utils.formatDateTime(el.startDate)}</Table.Cell>
                            <Table.Cell>{Utils.formatDateTime(el.endDate)}</Table.Cell>
                            <Table.Cell>{Utils.formatDateTime(el.job.nextRunAt)}</Table.Cell>
                            <Table.Cell
                                textAlign={"right"}>{(el.files?.length ?? 0) > 0 ?
                                <Popup flowing hoverable
                                       onOpen={() => setPopupVisible(true)}
                                       onClose={() => setPopupVisible(false)}
                                       trigger={<p style={{cursor: "pointer", color: "blue", fontWeight: "bolder"}}>{el.files!.length.toString()}</p>}>
                                    <Table celled verticalAlign='middle' color={"teal"}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Имя файла</Table.HeaderCell>
                                                <Table.HeaderCell>Размер</Table.HeaderCell>
                                                <Table.HeaderCell>Загрузка</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {el.files!.map((file, findex) => <Table.Row>
                                                <Table.Cell>{file.name}</Table.Cell>
                                                <Table.Cell>{file.size}</Table.Cell>
                                                <Table.Cell>
                                                    <Button icon onClick={(e, b) => {
                                                        Utils.openFile({fileId: file.id, download: true});
                                                    }}>
                                                        <Icon name={"download"}/>
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>)}
                                        </Table.Body>
                                    </Table>

                                </Popup>
                                : ""}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='9'>
                            <PagingFooter page={response?.page}
                                          totalPages={response?.totalPages}
                                          nextPage={response?.nextPage}
                                          prevPage={response?.prevPage}
                                          totalDocs={response?.totalDocs}
                                          loadPage={loadPage}/>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>);
}

export default TaskList;